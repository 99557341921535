import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ess-loading-spinner',
  imports: [CommonModule],
  template: `<div class="loading" [ngClass]="{ white: color === 'white', black: color === 'black' }"></div>`,
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input() color: 'white' | 'black' = 'white';
}
